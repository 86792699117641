<!-- main folder tree -->
<cdk-tree
  [dataSource]="mainFolderTreeDataSource()"
  [treeControl]="mainFolderTreeControl"
>
  <!-- root node -->
  <cdk-nested-tree-node
    *cdkTreeNodeDef="let node; when: isRootNode"
    class="folder-tree-node"
  >
    <div
      class="rounded-1 folder-node root-node"
      [class.folder--active]="node.id === selectedFolderId"
      [attr.aria-label]="'Toggle ' + node.name"
      id="nodeTreeRoot"
      cdkDropList
      (cdkDropListDropped)="drop($event)"
    >
      <!-- expand arrow button -->
      <i
        cdkTreeNodeToggle
        aria-label="toggle folder"
        [style.opacity]="node.children?.length > 0 ? '1' : '0'"
        class="me-1 cursor-pointer folder-arrow ri-arrow-right-s-line"
        [class.folderExpanded]="mainFolderTreeControl.isExpanded(node)"
      ></i>

      <!-- node text  -->
      <div
        class="folder-node-text root-node-text"
        (click)="selectFolder(node.id)"
      >
        {{ node.name | titlecase }}
      </div>
      @if (showNodeMenu()) {
        <div class="root-node-action">
          <!-- add folder to root button -->
          <button
            class="btn"
            ngbTooltip="Add Folder To Root"
            (click)="addFolder(node.id)"
          >
            <i class="ri-folder-add-fill"></i>
          </button>
        </div>
      }
    </div>

    <!-- children nodes -->
    <div
      [class.folder-tree-invisible]="!mainFolderTreeControl.isExpanded(node)"
    >
      <ng-container cdkTreeNodeOutlet></ng-container>
    </div>
  </cdk-nested-tree-node>

  <!-- folder nodes -->
  <cdk-nested-tree-node *cdkTreeNodeDef="let node" class="folder-tree-node">
    <div
      class="folder-node rounded-1"
      [class.folder--active]="node.id === selectedFolderId"
      [attr.aria-label]="'Toggle ' + node.name"
      cdkDropList
      [id]="node.id"
      (cdkDropListDropped)="drop($event)"
    >
      <i
        cdkTreeNodeToggle
        aria-label="toggle folder"
        [style.opacity]="node.children?.length > 0 ? '1' : '0'"
        class="me-1 cursor-pointer folder-arrow ri-arrow-right-s-line"
        [class.folderExpanded]="mainFolderTreeControl.isExpanded(node)"
      ></i>

      <div
        class="folder-node-text"
        (click)="selectFolder(node.id)"
        [ngbTooltip]="node.name"
      >
        {{ node.name }}
      </div>
      @if (showNodeMenu()) {
        <div class="folder-node-actions">
          <button
            mat-button
            [matMenuTriggerFor]="folderCommands"
            class="text-white hamburger-button"
          >
            <i class="ri-menu-line"></i>
          </button>

          <mat-menu #folderCommands="matMenu">
            <button
              mat-menu-item
              class="my-1 text-white badge bg-success"
              (click)="addFolder(node.id)"
            >
              Add Folder
            </button>

            <!-- edit folder button -->
            <button
              mat-menu-item
              class="my-1 text-white badge bg-primary"
              (click)="editFolder(node.id)"
            >
              Edit Folder
            </button>

            <!-- move folder button -->
            <button
              mat-menu-item
              class="my-1 text-white badge bg-primary"
              (click)="moveFolder(node.id, node.name)"
            >
              Move Folder
            </button>

            <!-- delete folder -->
            <button
              mat-menu-item
              class="my-1 mt-3 text-white badge bg-danger"
              (click)="deleteFolder(node.id, node.name)"
            >
              Delete Folder
            </button>
          </mat-menu>
        </div>
      }
    </div>

    <div
      [class.folder-tree-invisible]="!mainFolderTreeControl.isExpanded(node)"
    >
      <ng-container cdkTreeNodeOutlet></ng-container>
    </div>
  </cdk-nested-tree-node>
</cdk-tree>

<!-- trash folder tree -->
@if (showTrashFolderTree && trashFolderTreeDataSource()) {
  <div>
    <hr class="my-2" />

    <cdk-tree
      [dataSource]="trashFolderTreeDataSource()"
      [treeControl]="trashFolderTreeControl"
    >
      <!-- trash folder node -->
      <cdk-nested-tree-node
        *cdkTreeNodeDef="let node; when: isTrashNode"
        class="folder-tree-node"
      >
        <div
          class="folder-node"
          [class.folder--active]="node.id === selectedFolderId"
          [attr.aria-label]="'Toggle ' + node.name"
        >
          <i
            cdkTreeNodeToggle
            aria-label="toggle folder"
            [style.opacity]="node.children?.length > 0 ? '1' : '0'"
            class="me-1 cursor-pointer folder-arrow ri-arrow-right-s-line"
            [class.folderExpanded]="trashFolderTreeControl.isExpanded(node)"
          ></i>
          <div
            class="folder-node-text"
            (click)="selectFolder(node.id)"
            [class.text-muted]="mediaStore.getTotalItemsInFolder(node.id) === 0"
            [ngbTooltip]="
              node.length <= 0
                ? ('EMPTY' | translate)
                : ('TRASH_BIN_COUNT' | translate) +
                  mediaStore.getTotalItemsInFolder(node.id)
            "
          >
            {{ 'TRASH_BIN' | translate }}
          </div>

          <div class="folder-node-actions">
            <!-- trash folder's hamburger button -->
            @if (
              showNodeMenu() && mediaStore.getTotalItemsInFolder(node.id) > 0
            ) {
              <button
                class="text-white hamburger-button"
                mat-button
                [matMenuTriggerFor]="trashbinCommands"
              >
                <i class="ri-menu-line"></i>
              </button>
            }

            <mat-menu #trashbinCommands="matMenu">
              <button
                mat-menu-item
                class="my-1 text-white badge bg-danger"
                (click)="emptyTrashFolder(node)"
                [disabled]="disableEmptyTrashFolderButton"
              >
                <i class="ri-delete-bin-line me-2"></i>
                <span class="button-text">Empty Trash Folder</span>
              </button>
            </mat-menu>
          </div>
        </div>

        <div
          [class.folder-tree-invisible]="
            !trashFolderTreeControl.isExpanded(node)
          "
        >
          <ng-container cdkTreeNodeOutlet></ng-container>
        </div>
      </cdk-nested-tree-node>

      <!-- folder nodes (under the trash folder) -->
      <cdk-nested-tree-node *cdkTreeNodeDef="let node" class="folder-tree-node">
        <div
          class="folder-node"
          [class.folder--active]="node.id === selectedFolderId"
          [attr.aria-label]="'Toggle ' + node.name"
        >
          <i
            cdkTreeNodeToggle
            [style.opacity]="node.children?.length > 0 ? '1' : '0'"
            class="me-1 cursor-pointer folder-arrow ri-arrow-right-s-line"
            [class.folderExpanded]="trashFolderTreeControl.isExpanded(node)"
          ></i>
          <!-- [ngClass]="{
          'ri-arrow-down-s-line':
            trashFolderTreeControl.isExpanded(node),
          'ri-arrow-right-s-line':
            !trashFolderTreeControl.isExpanded(node)
        }" -->

          <div class="folder-node-text" (click)="selectFolder(node.id)">
            {{ node.name }}
          </div>

          <div class="folder-node-actions">
            <!-- trash folder's hamburger button -->
            @if (showNodeMenu()) {
              <div
                class="btn hamburger-button"
                mat-button
                [matMenuTriggerFor]="trashbinCommands"
              >
                <i class="ri-menu-line"></i>
              </div>
            }

            <mat-menu #trashbinCommands>
              <div class="d-flex flex-column gap-1">
                <!-- restore folder button -->
                <!-- TODO: restore functionality to be implemented -->
                <!-- <div class="btn btn-outline-primary button-icon-and-text">
                <i class="ri-folder-settings-line"></i>
                <div class="button-text">Restore Folder</div>
              </div> -->

                <!-- permanent delete folder -->
              </div>
              <button
                mat-menu-item
                class="my-1 text-white badge bg-danger"
                (click)="permanentDeleteFolder(node.id, node.name)"
              >
                <i class="ri-delete-bin-line me-2"></i>
                <span class="button-text">Permanently Delete Folder</span>
              </button>

              <!-- move folder button -->
              <button
                mat-menu-item
                class="my-1 text-white badge bg-primary"
                (click)="moveFolder(node.id, node.name)"
              >
                <span class="button-text">Move Folder</span>
              </button>
            </mat-menu>
          </div>
        </div>

        <div
          [class.folder-tree-invisible]="
            !trashFolderTreeControl.isExpanded(node)
          "
        >
          <ng-container cdkTreeNodeOutlet></ng-container>
        </div>
      </cdk-nested-tree-node>
    </cdk-tree>
  </div>
}
